
import Game from "../../services/game"
import Web3 from "web3";
import GameHelper from "../helpers/gameHelper.js";


export default class DiceGame extends Game {
  win = 0
  amount = 0.001
  lastBlock = 0
  tx_id = ''


  constructor(_messageHandler, _Contract, _game) {
    super(_messageHandler, _Contract, _game)
    window.Logger.log('DiceGame created')
    this.gameHelper = new GameHelper(_game)
  }



  betHandler(amount, under, slider) {

    if (this.tokenObj.address == '0x0000000000000000000000000000000000000000') {
      this.betMainHandler(amount, under, slider)
    } else {
      this.betTokenHandler(amount, under, slider)
    }
  }

  async betMainHandler(amount, under, slider) {
    if (!this.connected) {
      this.messageHandler({ type: 'error', msg: 'Not connected' })
      return
    }

    this.amount = amount;

    this.messageHandler({ type: 'betloader' })
    if (localStorage) {
      localStorage.setItem('betAmount', this.amount);
    }

    var sendData = {
      from: this.account,
      value: Web3.utils.toWei("" + this.amount, 'ether')
    }

    if (this.tokenObj.extraFeeGasLimit > 0) {
      var gasLimit = await this.contract.methods.bet(under, slider).estimateGas(
        {
          from: this.account,
          value: Web3.utils.toWei("" + this.amount, 'ether')
        }
      )
      gasLimit = parseInt(gasLimit * this.tokenObj.extraFeeGasLimit)

      var gasPrice = await window.web3.eth.getGasPrice()
      gasPrice = parseInt(gasPrice * this.tokenObj.extraFeeGasPrice)


      sendData.gasLimit = gasLimit
      sendData.gasPrice = gasPrice
    }


    console.log(sendData)



    window.Logger.log("this.account " + this.account)
    this.contract.methods
      .bet(under, slider)
      .send(sendData)
      .then(function (result) {
        window.Logger.log(result)
        this.win = Web3.utils.fromWei(result.events.Bet.returnValues.win, 'ether');
        this.tx_id = result.transactionHash
        this.messageHandler({
          type: 'betfinished',
          address: result.events.Bet.returnValues.player,
          win: this.win,
          tx_id: result.transactionHash,
          bet: this.amount,
          currency: this.tokenObj.name,
          number: "" + (parseFloat(result.events.Bet.returnValues.number) / 100).toFixed(2),
          up: result.events.Bet.returnValues.up,
          position: result.events.Bet.returnValues.position,
        })
        this.fetchAccount();
      }.bind(this), function (params) {
        this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
        this.messageHandler({ type: 'betbutton' })
      }.bind(this))

  }

  async betTokenHandler(amount, under, slider) {
    if (!this.connected) {
      this.messageHandler({ type: 'error', msg: 'Not connected' })
      return
    }

    this.amount = amount;
    this.messageHandler({ type: 'betloader' })
    if (localStorage) {
      localStorage.setItem('betAmount', this.amount);
    }
    //address token_address, bool up, uint256 position, uint amount
    window.Logger.log(this.tokenObj)
    window.Logger.log(`amount=${this.amount} under=${under}  slider=${slider}  decimals=${this.tokenObj.decimals}`)


    var betAmt = Web3.utils.toWei("" + this.amount, this.tokenObj.decimals)

    var sendData = {
      from: this.account
    }


    if (this.tokenObj.extraFeeGasLimit > 0) {
      var gasLimit = await this.contract.methods.bet(under, slider, this.tokenObj.address, betAmt).estimateGas(
        { from: this.account }
      )

      gasLimit = parseInt(gasLimit * this.tokenObj.extraFeeGasLimit)

      var gasPrice = await window.web3.eth.getGasPrice()
      gasPrice = parseInt(gasPrice * this.tokenObj.extraFeeGasPrice)


      sendData.gasLimit = gasLimit
      sendData.gasPrice = gasPrice
    }



    console.log(sendData)

    this.contract.methods
      .bet(under, slider, this.tokenObj.address, betAmt)
      .send(sendData)
      .then(function (result) {
        this.win = Web3.utils.fromWei(result.events.Bet.returnValues.win, this.tokenObj.decimals);
        this.tx_id = result.transactionHash

        this.messageHandler({
          type: 'betfinished',
          address: result.events.Bet.returnValues.player,
          tx_id: result.transactionHash,
          win: this.win,
          bet: this.amount,
          currency: this.tokenObj.name,
          number: "" + (parseFloat(result.events.Bet.returnValues.number) / 100).toFixed(2),
          up: result.events.Bet.returnValues.up,
          position: result.events.Bet.returnValues.position,
        })
        this.erc20Game.balanceOf()
      }.bind(this), function (params) {
        this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
        this.messageHandler({ type: 'betbutton' })
      }.bind(this))

  }


}

