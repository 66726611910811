<template>
  <div class="container pt-3">
    <div class="row">
      <div class="m-0 p-0">
        <div class="clearfix"></div>
        <div class="gameplay">
          <div v-if="haveCashback" class="claim">
            <div class="float-left">
              <span class="responsiveElipsis">{{ cashbackAmount }} {{ currency }}</span>
            </div>
            <div class="float-right">
              <button @click="this.claimCashback()" v-bind:class="cashbackClaiming == true ? 'txtdisabled' : ''"
                title="Claim Cashback">
                CLAIM
              </button>
              <button title="About Cashback" data-toggle="modal" data-target="#exampleModalLive"
                style="margin-left: 5px; padding: 5px 15px" @click="showPopup">
                <img src="/images/info.svg" style="width: 20px; height: 20px" />
              </button>
            </div>
            <div class="clearfix"></div>
          </div>
          <div v-if="haveCashback" class="text-start text-hint ps-1 pt-1 mb-3">
            Instant Cashback
          </div>

          <div @click="autobetStop">
            <div v-bind:class="
              this.autobetSettings.isRolling == true ? 'disabled' : ''
            ">
              <div class="gamemode mb-3">
                <DiceTab :changeHandler="diceTabHandler" v-bind:name="'ManualAuto'" v-bind:leftText="'Manual'"
                  v-bind:rightText="'Auto'" />
              </div>

              <div style="height: 50px; margin-top: 20px; line-height: 50px">
                <div class="diceresult">
                  <span v-if="rolled == '00.00'" class="wintxtdefault">00.00</span>
                  <span class="wintxt" v-if="rolled != '00.00'" v-bind:class="state.dicewinState">{{ rolled }}</span>
                </div>
              </div>
              <div>
                <div class="dicesettigsholder">
                  <div class="dicesettigs">
                    <div class="dicesettigstxt">Multiplier</div>
                    <div class="dicesettigsinput">
                      {{ mult }}<span> X</span>
                    </div>
                  </div>
                  <div class="dicesettigs">
                    <div class="dicesettigstxt" v-if="upVal">Roll Over</div>
                    <div class="dicesettigstxt" v-if="!upVal">Roll Under</div>
                    <div class="dicesettigsinput">
                      {{ sliderPos }}<span><i class="tio-autorenew"></i></span>
                    </div>
                  </div>
                  <div class="dicesettigs">
                    <div class="dicesettigstxt">Win Chance</div>
                    <div class="dicesettigsinput">
                      {{ winchance }}<span>%</span>
                    </div>
                  </div>
                  <div class="clearleft"></div>
                </div>
              </div>

              <div class="diceRange">
                <input type="text" id="diceRangeSlider" ref="diceRangeSlider" />
              </div>

              <div class="mt-3">
                <div class="text-start text-hint ps-1">
                  <span style="color: #333333">Bet Amount</span>
                  (Min {{ minBet }} / Max {{ maxBet }} {{ currency }})
                </div>
                <div class="dicebet">
                  <div class="gameBet">
                    <input class="betinput" type="number" v-model="betAmount" />
                    <button @click="betAmount = gameHelper.modifyBet(betAmount, 1)" class="betModifiers bmleft">
                      x2
                    </button>
                    <button @click="betAmount = gameHelper.modifyBet(betAmount, 2)" class="betModifiers bmright">
                      x/2
                    </button>
                    <div class="clearleft"></div>
                  </div>
                </div>
                <div class="text-start text-hint ps-1 pt-1" v-if="betAmount > 0 && haveCashback">
                  Cashback amount {{ calculateCashback(betAmount) }}
                  {{ currency }}
                </div>
              </div>
            </div>
          </div>
          <div v-bind:class="
            state.isAuto == true && autobetSettings.isRolling == false
              ? 'autoshow'
              : 'autohide'
          ">
            <div style="margin-top: 20px">
              <div class="text-start text-hint ps-1">
                <span style="color: #333333">Number of bets</span>
              </div>
              <div class="dicebet">
                <div class="gameBet">
                  <input class="betinput fl" type="number" v-model="autobetSettings.numberOfBets" />
                  <span class="fr" style="line-height: 40px; color: #cbcbcb; margin-right: 5px"><i
                      class="tio-infinity"></i></span>
                  <div class="clearleft clearright"></div>
                </div>
              </div>
            </div>
            <div class="clearleft"></div>
            <div style="margin-top: 20px">
              <div class="text-start text-hint ps-1">
                <span style="color: #333333">On Win</span>
              </div>
              <div>
                <div style="width: 65%; float: left">
                  <DiceTab :changeHandler="diceTabHandler" v-bind:name="'OnWin'" v-bind:leftText="'Reset'"
                    v-bind:rightText="'Increase'" />
                </div>
                <div style="width: 30%; float: right">
                  <div class="dicebet">
                    <div v-bind:class="
                      autobetSettings.onWinState == true ? '' : 'txtdisabled'
                    ">
                      <input class="betinput fl" type="number" v-model="autobetSettings.onWin" />
                      <span class="fr" style="
                          line-height: 40px;
                          color: #cbcbcb;
                          margin-right: 5px;
                        ">%</span>
                      <div class="clearleft clearright"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="clearleft"></div>
            <div style="margin-top: 20px">
              <div class="text-start text-hint ps-1">
                <span style="color: #333333">On Loss</span>
              </div>
              <div>
                <div style="width: 65%; float: left">
                  <DiceTab :changeHandler="diceTabHandler" v-bind:name="'OnLoss'" v-bind:leftText="'Reset'"
                    v-bind:rightText="'Increase'" />
                </div>
                <div style="width: 30%; float: right">
                  <div class="dicebet">
                    <input class="betinput fl" v-bind:class="
                      autobetSettings.onLossState == true ? '' : 'txtdisabled'
                    " type="number" v-model="autobetSettings.onLoss" />
                    <span class="fr" style="
                        line-height: 40px;
                        color: #cbcbcb;
                        margin-right: 5px;
                      ">%</span>
                    <div class="clearleft clearright"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="clearleft"></div>
            <div style="margin-top: 20px">
              <div class="text-start text-hint ps-1">
                <span style="color: #333333">Stop on profit</span>
              </div>
              <div class="dicebet">
                <div class="gameBet">
                  <input class="betinput fl" type="number" v-model="autobetSettings.onProfitWin" />
                  <span class="fr" style="line-height: 40px; color: #cbcbcb; margin-right: 5px">{{ config.currency_code
                  }}</span>
                  <div class="clearleft clearright"></div>
                </div>
              </div>
            </div>
            <div class="clearleft"></div>
            <div style="margin-top: 20px">
              <div class="text-start text-hint ps-1">
                <span style="color: #333333">Stop on loss</span>
              </div>
              <div class="dicebet">
                <div class="gameBet">
                  <input class="betinput fl" type="number" v-model="autobetSettings.onProfitLoss" />
                  <span class="fr" style="line-height: 40px; color: #cbcbcb; margin-right: 5px">{{ config.currency_code
                  }}</span>
                  <div class="clearleft clearright"></div>
                </div>
              </div>
            </div>
            <div class="clearleft"></div>
          </div>
        </div>

        <div class="buttons mb-3">
          <div class="diceconnect" v-if="state.buttonstate == 'unlock'">
            <button class="betbutton" @click="diceGame.unlock()">UNLOCK</button>
          </div>
          <div class="clearfix"></div>
          <div class="diceconnect" v-if="state.buttonstate == 'connect'">
            <button class="betbutton" @click="diceGame.connect()">
              CONNECT
            </button>
          </div>
          <div class="clearfix"></div>
          <div class="diceconnect" v-if="state.buttonstate == 'play'">
            <button v-if="!this.state.isAuto" class="betbutton" @click="makeBet" :disabled="!state.gameBet">
              <span v-if="state.gameBetloader" class="spinner-border text-warning me-1" role="status"
                aria-hidden="true"></span>
              ROLL DICE
            </button>
            <button v-if="this.state.isAuto" class="betbutton" @click="autobetStart">
              <span v-if="autobetSettings.isRolling" class="spinner-border text-warning me-1" role="status"
                aria-hidden="true"></span>
              {{
                  autobetSettings.isRolling == true
                    ? "STOP AUTOROLL"
                    : "START AUTOROLL"
              }}
              {{
                  autobetSettings.numberOfBetsActive == true
                    ? "(" + autobetSettings.numberOfBets + ")"
                    : ""
              }}
            </button>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="betstabHolder pt-1">
          <div class="betstab" v-bind:class="
            historyManager.currentBetHistoryList == 'MY'
              ? 'betstabactive'
              : ''
          " @click="historyManager.setBetHistoryList('MY')">
            MY
          </div>
          <div class="betstab" v-bind:class="
            historyManager.currentBetHistoryList == 'ALL'
              ? 'betstabactive'
              : ''
          " @click="historyManager.setBetHistoryList('ALL')">
            ALL
          </div>
        </div>

        <div class="clearfix"></div>
        <div class="betstabbottomline"></div>

        <DiceHistoryTable :data="historyManager.betHistory" />
      </div>
    </div>
    <Popup v-show="wrongNetwork.state" @close="closePopup" :title="wrongNetwork.title" :body="wrongNetwork.body"
      fontSize="normal" />
    <Popup v-show="isPopupVisible" @close="closePopup" title="INSTANT CASHBACK RULES" :body="getCashbackText()" />
    <PromoPopup
      :state="dailyCashbackPopup.state"
      :title="dailyCashbackPopup.title"
      :body="dailyCashbackPopup.body"
      :closeButton="true"
      fontSize="normal"
    />
  </div>
</template>

<script>
import HistoryManager from "../../../services/historymanager";
import DiceGame from "../index.js";
import CashbackHelper from "../../../services/cashbackhelper.js";

import Dice from "../contracts/Dice.min.json";
import Cashback from "../../../services/Cashback.min.json";

import Token from "../../../services/Token.min.json";
import TokenHelper from "../../../services/tokenhelper.js";


import Promo2 from "../../../services/Promo2.min.json";
import Promo2Helper from "../../../services/promo2helper";

import gameHelper from "../../helpers/gameHelper.js";
import DiceHistoryTable from "./DiceHistoryTable";
import DiceTab from "./DiceTab";
import DiceRangeSlider from "./DiceRangeSlider";
import Popup from "@/components/Popup.vue";
import PromoPopup from "@/components/PromoPopup.vue";

export default {
  name: "GamePlay",
  components: {
    DiceHistoryTable,
    DiceTab,
    Popup,
    PromoPopup
  },
  data() {
    const state = {
      dicemessage: true,
      dicewin: false,
      dicewinState: 0,
      diceWinAmount: "0.0",
      gameBet: false,
      buttonstate: "connect",
      gameBetloader: false,
      diceMessageStr: "Connecting...",
      game: "dice",
      seletectedItems: 0,
      isAuto: false,
    };

    var diceGame = null;
    var cashbackHelper = null;
    var tokenHelper = null
    var promo2Helper = null
    var diceSlider = new DiceRangeSlider();
    return {
      diceGame,
      cashbackHelper,
      tokenHelper,
      promo2Helper,
      diceSlider,
      historyManager: new HistoryManager(this.historyHandler, "dice", Dice),
      state,
      config: this.$store.getters.getConfig,
      gameHelper: new gameHelper(state.game),
      betAmount: this.$store.getters.getGameLimits[state.game].min,
      minBet: this.$store.getters.getGameLimits[state.game].min,
      maxBet: this.$store.getters.getGameLimits[state.game].max,
      currency: this.$store.getters.getCurrency,
      haveCashback: true,
      sliderPos: 50,
      rake: 1,
      upVal: true,
      winchance: 0,
      mult: 0,
      rolled: "00.00",
      autobetSettings: {
        isRolling: false,
        numberOfBets: 0,
        numberOfBetsOrigin: 0,
        numberOfBetsActive: false,
        state: false,
        onWin: 0,
        onLoss: 0,
        onWinState: false,
        onLossState: false,

        onProfitWin: 0,
        onProfitLoss: 0,
        profit: 0,
        bet: {
          initial: 0,
          current: 0,
        },
        randomNumbers: {
          onWin: false,
          onLoss: false,
          make: false,
        },
      },
      cashbackAmount: 0,
      cashbackClaiming: true,
      isPopupVisible: false,
      wrongNetwork: {
        state: false,
        title: 'Wrong Network',
        body: `You are trying to play with<br/> {{url network}} but you are connected to {{user network}}. 
                Please connect to {{url networ}} and click reload button below
                <div class="text-center"><a class="btn btn-secondary btn-sm mt-3">Reload</a></div>`,
      },
      dailyCashbackPopup: {
        state: false,
        title: "We've got you covered!",
        body: `<center><div style="text-align: left;font-size:19px;font-weight:600;">As it was your first bet today in this game, we have REFUNDED 1 TT back to your Cashback balance, to retrieve it just press the cashback "Claim" button!</div></center>`,
      }
    };
  },
  unmounted() {
    this.diceGame.destroy();
    this.cashbackHelper.destroy();
    this.promo2Helper.destroy();
    this.tokenHelper.destroy()
    this.historyManager.destroy()
  },
  beforeMount() {
    this.diceGame = new DiceGame(this.messageHandler, Dice, "dice");
    this.diceGame.init();

    this.cashbackHelper = new CashbackHelper(this.messageHandler, Cashback);

    this.tokenHelper = new TokenHelper(this.messageHandler, Token);
    
    this.promo2Helper = new Promo2Helper(this.messageHandler, Promo2);
    

    this.$store.getters.getConfig.games[this.state.game].contract =
      this.diceGame.address;
    this.haveCashback = this.$store.state.user.currency.cashback;
    window.Logger.log(this.$store.state.user.currency);
  },
  mounted() {
    this.$store.commit("setEnableFast", false);
    window.Logger.log("mounted");
    //diceRangeSlider

    this.diceSlider.init({
      target: "#diceRangeSlider",
      values: { min: 4, max: 97 },
      range: false,
      set: [50],
      step: 1,
      up: true,
      scale: false,
      labels: false,
      tooltip: false,
      onChange: function (vals) {
        this.setSliderPos(vals);
      }.bind(this),
    });

    this.$nextTick(function () {
      window.addEventListener("resize", this.matchSizes);
    });
    this.calculateWinState();
  },
  watch: {
    windowSize: function () {
      this.matchSizes();
    },
    "state.diceMessageStr": function (gameMessage) {
      if (gameMessage != "") {
        this.$toast.show(gameMessage, {
          duration: 13000,
        });
      }
      this.state.diceMessageStr = "";
    },
    "$store.state.user.currency": function () {
      this.setCurrency(this.$store.state.user.currency);
    },
  },
  methods: {
    setCurrency(_currency) {
      window.Logger.log("setCurrency");
      window.Logger.log(_currency);
      this.currency = _currency.name.toUpperCase();
      this.haveCashback = _currency.cashback;

      this.diceGame.setCurrency(_currency);
      if (_currency.address == '0x0000000000000000000000000000000000000000') {
        this.state.buttonstate = "play"
      }
      //this.state.buttonstate = "play";
      this.minBet = this.$store.getters.getGameLimits[this.state.game].min;
      this.maxBet = this.$store.getters.getGameLimits[this.state.game].max;
    },
    setSliderPos(sp) {
      this.sliderPos = sp;
      this.calculateWinState();
    },
    changeUpVal() {
      this.upVal = !this.upVal;
      this.diceSlider.setUp(this.upVal);
      this.calculateWinState();
    },
    calculateWinState() {
      var payoutNum = 98;
      if (this.upVal) {
        this.winchance = 100 - this.sliderPos;
      } else {
        this.winchance = this.sliderPos - 1;
      }

      this.mult = (payoutNum / this.winchance).toFixed(4);
    },
    diceTabHandler(mode, name) {
      switch (name) {
        case "ManualAuto":
          this.state.isAuto = mode;
          break;
        case "OnWin":
          this.autobetSettings.onWinState = mode;
          this.autobetSettings.onWin = 0;
          break;
        case "OnLoss":
          this.autobetSettings.onLossState = mode;
          this.autobetSettings.onLoss = 0;
          break;
        default:
          break;
      }
    },
    makeBet() {
      if (!this.gameHelper.verify("betAmount", this.betAmount)) {
        return this.$toast.error(
          this.gameHelper.getMessage("invalid_bet_amount")
        );
      }

      this.diceGame.betHandler(this.betAmount, this.upVal, this.sliderPos);
    },
    autobetStart() {
      if (this.autobetSettings.isRolling) {
        return this.autobetStop();
      }
      if (this.state.isAuto) {
        this.autobetSettings.isRolling = true;
        window.scrollTo(0, 0);
        //return;
      }
      if (this.autobetSettings.numberOfBets > 0) {
        this.autobetSettings.numberOfBetsOrigin =
          this.autobetSettings.numberOfBets;
        this.autobetSettings.numberOfBetsActive = true;
      }
      this.autobetSettings = this.gameHelper.autobetStart(this.autobetSettings);
      return this.makeBet();
    },
    autobetStop() {
      if (!this.autobetSettings.isRolling) {
        return;
      }
      this.autobetSettings.isRolling = false;
      this.autobetSettings.numberOfBetsActive = false;
      this.autobetSettings.numberOfBets =
        this.autobetSettings.numberOfBetsOrigin;
      this.autobetSettings = this.gameHelper.autobetStop(this.autobetSettings);
      this.betAmount = this.autobetSettings.bet.current;
      window.scrollTo(0, 0);
    },
    autobetProcess(result) {
      if (this.autobetSettings.numberOfBetsActive) {
        this.autobetSettings.numberOfBets =
          this.autobetSettings.numberOfBets - 1;
        if (this.autobetSettings.numberOfBets <= 0) {
          this.autobetStop();
          return;
        }
      }
      this.autobetSettings = this.gameHelper.autobet(
        this.autobetSettings,
        result
      );
      if (this.autobetSettings.state) {
        //start new raund
        this.betAmount = this.autobetSettings.bet
          ? this.autobetSettings.bet.current
          : 0;
        return this.makeBet();
      }
      //finish autobet
      this.autobetStop();
    },
    matchSizes() { },
    historyHandler(obj, list) {
      if (this.diceGame.playeraddress != obj.address) {
        this.historyManager.addBetHistory(obj, list)
      }

    },
    messageHandler(obj) {
      window.Logger.log("obj.type " + obj.type)
      if (obj.type == "limits") {
        this.betAmount = obj.min;
        this.minBet = obj.min;
        this.maxBet = obj.max;
        this.$store.getters.getGameLimits[this.state.game].min = this.minBet;
        this.$store.getters.getGameLimits[this.state.game].max = this.maxBet;
        this.state.gameBet = true;
        return;
      }
      //
      if (obj.type == "tokennotalowed") {
        this.state.buttonstate = "unlock";
        return;
      }
      if (obj.type == "tokenalowed") {
        this.state.buttonstate = "play";
        return;
      }
      if (obj.type == "networkchange") {
        console.log(obj);
        var url = this.gameHelper.getRedirectUrl(this.state.game, obj.networkVersion)
        console.log(url)
        if (url != null) {
          window.location.href = url
        } else {
          var chains = this.gameHelper.getChainsList(this.state.game)
          window.Logger.log(chains)
          this.wrongNetwork.state = true
          this.wrongNetwork.body = "This game is not available on this Chain/Token<br/><br/>"
          this.wrongNetwork.body += "You can play this game on the next Cnain(Tokens)<br/><br/>"
          for (let index = 0; index < chains.length; index++) {
            const element = chains[index];
            this.wrongNetwork.body += `${element.blockchain_name}(${element.currency_codes.join(', ')})<br/><br/>`
          }
          this.wrongNetwork.body += "Please change to correct Chain/Token<br/>"
          this.wrongNetwork.body += `and click reload button below
                <div class="text-center"><a href="${window.location.href}" class="btn btn-secondary btn-sm mt-3">Reload</a></div>`


        }
        // 
        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }
      if (obj.type == "cashbackadd") {
        this.cashbackAmount = obj.balance;
        if (parseFloat(this.cashbackAmount) > 0) {
          this.cashbackClaiming = false;
        }

        return;
      }

      if (obj.type == "tokenbalance") {
        console.log(obj)
        this.$store.commit("setTokenBalance", parseFloat(obj.balance).toFixed(3));
        return;
      }

      if (obj.type == "cashbackclaim") {
        this.$toast.success("Claimed " + obj.amount + " " + this.currency, {
          duration: 3000,
        });
        this.diceGame.fetchAccount();
        this.cashbackHelper.balanceOf();
        return;
      }

      if (obj.type == "dailycashback") {
        console.log(obj)

        this.dailyCashbackPopup.state = true;
      }

      if (obj.type == "accountset") {
        this.state.buttonstate = "play";
        if (this.$store.getters.getUserWallet != obj.msg) {
          this.$store.commit("setUserWallet", obj.msg);
        }

        this.diceGame.playeraddress = obj.msg;
        
        if (
          this.$store.state.user.currency.address !=
          this.diceGame.tokenObj.address
        ) {
          this.setCurrency(this.$store.state.user.currency);
        }
        if (this.cashbackHelper.account == null) {
          this.cashbackHelper.onConnet(obj.msg);
          this.cashbackHelper.balanceOf();
        }

        if (this.tokenHelper.account == null) {
          this.tokenHelper.onConnet(obj.msg);
          this.tokenHelper.balanceOf();
        }
        

        if (this.promo2Helper.account == null) {
          console.log(obj.msg)
          this.promo2Helper.onConnet(obj.msg);
        }

        return;
      }

      if (obj.type == "balance") {
        if (obj.currency != this.currency) {
          return
        }
        window.Logger.log(obj);
        let balance = parseFloat(obj.balance);
        if(obj.currency === 'TT'){
          this.$store.commit("setBalance", balance.toFixed(2));
        }else{
          this.$store.commit("setBalance", balance.toFixed(5));
        }
        this.$store.commit("setCurrency", obj.currency);
        return;
      }

      if (obj.type == "bethistory") {
        return;
      }

      if (obj.type == "error") {
        this.state.diceMessageStr = obj.msg;
        this.state.dicemessage = true;
        setTimeout(
          function () {
            this.state.dicemessage = false;
          }.bind(this),
          3000
        );
        if (this.autobetSettings) {
          this.autobetStop();
        }
      } else {
        this.state.dicewin = false;
        this.state.gameBet = false;
        //this.state.buttonstate = "play";
        this.state.gameBetloader = false;

        //type=connected modis roca metamaskze dakonektda
        if (obj.type == "connected") {
          window.Logger.log(this.$store.getters.getConfig.games[this.state.game])
          this.$store.commit("setCurrencyEnabled", this.$store.getters.getConfig.games[this.state.game].tokens);
          this.state.dicemessage = false;
          this.state.gameBet = true;
        }

        //type=rejected modis roca userma metamaskze rejecti misca konektze da vachvenebt konektis gilaks
        //type=notconnected  modis roca useri araa dakonektebuli da vachvenebt konektis gilaks
        //da vmalavt mesijis divs
        if (obj.type == "rejected" || obj.type == "notconnected") {
          this.state.buttonstate = "connect";
          this.state.dicemessage = false;
          this.autobetStop();
        }

        //type=betloader modis roca beti gavida blockchainze da velodebit dadasturebas, vachvenenbt preloaders
        if (obj.type == "betloader") {
          this.state.gameBetloader = true;
        }

        //type=betfinished modis roca userma an moigo an waago obj.win ari tanxa
        // tu tanxa=0 chveulebrivad betis knopkas vachvenebt
        // tu tanxa>0 chveulebrivad betis knopkas vachvenebt
        if (obj.type == "betfinished") {
          this.tokenHelper.balanceOf();
          this.historyManager.addBetHistory(
            {
              address: "ME",
              bet: obj.bet,
              tx_id: obj.tx_id,
              win: obj.win,
              up: obj.up,
              currency: obj.currency,
              position: obj.position,
              number: obj.number,
            },
            "MY"
          );
          this.historyManager.addBetHistory(
            {
              address: obj.address,
              bet: obj.bet,
              tx_id: obj.tx_id,
              win: obj.win,
              up: obj.up,
              currency: obj.currency,
              position: obj.position,
              number: obj.number,
            },
            "ALL"
          );
          this.rolled = obj.number;
          this.state.dicewinState = "wintxtlost";
          if (obj.win > 0) {
            this.state.dicewin = true;
            this.state.diceWinAmount = obj.win;
            //or wamshi vmalavt winis mesijs da vachvenebt betis knopkas
            this.$toast.success(
              this.gameHelper.getMessage("you_won_amount", obj.win),
              { duration: 3000 }
            );
            this.state.dicewin = false;
            this.state.dicewinState = "wintxtwin";

            this.state.gameBet = true;
            this.$soundManager.play({ name: "win" });
          } else {
            this.state.gameBet = true;
          }
          setTimeout(() => {
            this.state.dicewinState = "";
          }, 1000);
          if (this.autobetSettings.state) {
            this.autobetProcess(obj);
          }
        }
        if (obj.type == "betbutton") {
          this.state.gameBet = true;
        }
      }
    },
    claimCashback() {
      this.cashbackClaiming = true;
      this.cashbackHelper.claim();
    },
    calculateCashback(betAmount) {
      if (betAmount < 3000) {
        return (betAmount * 50) / 10000;
      } else if (betAmount < 5000) {
        return (betAmount * 60) / 10000;
      } else if (betAmount < 10000) {
        return (betAmount * 70) / 10000;
      } else {
        return (betAmount * 80) / 10000;
      }
    },
    showPopup() {
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
      this.wrongNetwork.state = false
    },
    getCashbackText() {
      return `<table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Bet amount</th>
                    <th scope="col">Cashback</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>= 10 000</td>
                    <td>0.8%</td>
                  </tr>
                  <tr>
                    <td>< 10 000</td>
                    <td>0.7%</td>
                  </tr>
                  <tr>
                    <td>< 5 000</td>
                    <td>0.6%</td>
                  </tr>
                  <tr>
                    <td>< 3 000</td>
                    <td>0.5%</td>
                  </tr>
                </tbody>
              <table>

              Example table:<br>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Bet amount</th>
                    <th scope="col">Cashback</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>10 000</td>
                    <td>80</td>
                  </tr>
                  <tr>
                    <td>5 000</td>
                    <td>35</td>
                  </tr>
                  <tr>
                    <td>3 000</td>
                    <td>18</td>
                  </tr>
                  <tr>
                    <td>1 000</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>200</td>
                    <td>1</td>
                  </tr>
                </tbody>
              </table>

              * Cashback is rewarded only on lost bets<br>
              * Cashback is rewarded for any bet size<br>
              * Cashback has no wagering terms, can be claimed instantly<br>`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.gameplay {
  width: 90%;
  margin: 0 auto;
}

.diceRange {
  background: linear-gradient(180deg, #d9d9d9 0%, #d9d9d9 100%);
  width: 100%;
  height: 40px;
  /* padding: 10px; */
  border-radius: 5px;
  position: relative;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

.gamemode {
  width: 100%;
  height: 45px;
  margin: 0 auto;
}

.autohide {
  height: 0px;
  overflow: hidden;
  transition: 0.5s;
}

.autoshow {
  height: 425px;
  overflow: hidden;
  transition: 0.5s;
}

.txtdisabled {
  pointer-events: none;
  opacity: 0.5;
}

.disabled {
  pointer-events: none;
  opacity: 0.9;
}

.smallHistory {
  float: left;
  background: #ccc;
  padding: 4px;
  font-size: 12px;
  border-radius: 5px;
  margin: 3px;
  color: #a3a3a3;
}

.wintxtdefault {
  color: #dcd8e3;
}

.wintxtlost {
  color: #ff5353;
}

.wintxtwin {
  color: #2b9320;
}

.dicesettigsholder {
  width: 100%;
  margin: 0 auto;
}

.diceresult {
  width: 100%;
  text-align: center;
  height: 50px;
  font-size: 35px;
}

.dicesettigs {
  width: 33%;
  float: left;
  font-size: 12px;
  padding: 10px;
}

.dicesettigsinput span {
  color: #c4c4c4;
}

.dicesettigsinput {
  width: 100%;
  background: #ffffff;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
}

.dicesettigstxt {
  width: 100%;
  text-align: center;
}

.dicebet {
  background: #ffffff;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.18);
  width: 100%;
  height: 50px;
  padding: 5px;
  border-radius: 5px;
  margin: 0 auto;
  position: relative;
}

.diceslider {
  background: #e0e0e0;
  width: 100%;
  height: 45px;
  padding: 5px;
  border-radius: 5px;
  margin: 0 auto;
  position: relative;
  margin-top: 20px;
}

.itemColumn {
  display: inline-block;
}

.container {
  background: #f0edf6 !important;
}

.hittxt {
  color: #9e9e9e;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.clearright {
  clear: right;
}

.clearleft {
  clear: left;
}

.betstabbottomline {
  height: 2px;
  background: #cfcfcf;
  width: 90%;
  margin: 0 auto;
}

.betstab {
  width: 30%;
  float: left;
  color: var(--tabtxt-color);
  margin-left: 2px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: linear-gradient(0deg,
      var(--tabgs-color) 0%,
      var(--tabge-color) 100%);
  transition: 0.3s;
}

.betstabactive {
  color: var(--tabtxtselected-color);
  background: linear-gradient(0deg,
      var(--tabys-color) 0%,
      var(--tabye-color) 100%);
  transition: 0.3s;
}

.betstabHolder {
  justify-content: center;
  align-items: center;
}

.buttons {
  margin: 30px 0px;
}

.gameBet {
  text-align: left;
  padding-left: 10px;
}

.diceconnect,
.gameBet {
  max-width: 100%;
  margin: 0 auto;
}

.diceconnect .betbutton {
  margin: 0 auto !important;
  float: none;
}

.dicemessage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #cbcbcb;
}

.dicemessage {
  z-index: 1;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.visible {
  opacity: 1;
  transition: 0.5s;
  pointer-events: auto;
}

.hidden {
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
}

.visiblefast {
  opacity: 1;
  pointer-events: auto;
}

.hiddenfast {
  opacity: 0;
  pointer-events: none;
}

.betinput {
  font-size: 25px;
  border: none;
  padding: 5px;
  outline: none;
  padding-top: 0px;
  max-width: 300px;
  width: 59%;
}

.betbutton {
  background: #262626;
  border-radius: 5px;
  font-size: 18px;
  color: #ffffff;
  border: none;
  width: 100%;
  text-align: center;
  padding: 5px 5px 5px 10px;
  max-width: 250px;
}

.randombutton {
  background: #262626;
  border-radius: 5px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  padding: 5px 10px;
  margin-top: 10px;
  margin-right: 10px;
  float: left;
}

.betModifiers {
  width: 50px;
  background: #eaeaea;
  box-shadow: inset 0px -2px 0px #e3e3e3;

  border: none;
  color: #333333;
  text-align: center;
  padding: 7px 0px;
  top: 1px;
  position: relative;
  margin-left: 5px;
  height: 40px;
}

.bmleft {
  border-radius: 5px 35px 35px 5px;
  float: right;
}

.bmright {
  border-radius: 35px 5px 5px 35px;
  float: right;
}

.bet {
  width: 5em;
}

.spinner-border {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: text-center;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
  top: -3px;
  position: relative;
}

.claim {
  width: 100%;
  background: #fff;
  padding: 10px 12px;
}

.claim span {
  margin-top: 4px;
  display: inline-block;
}

.claim button {
  border: none;
  outline: none;
  padding: 5px 30px;
  border-radius: 5px;
  color: #333333;
}

.claim img {
  position: relative;
  top: -1px;
}

@media (max-width: 992px) {
  .buttons {
    margin: 0 auto;
    max-width: 90%;
    margin-top: 1.5em;
  }
}

@media (max-width: 401px) {
  .responsiveElipsis {
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>