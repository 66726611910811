

export default class DiceRangeSlider {
    input = null;
    inputDisplay = null;
    slider = null;
    sliderWidth = 0;
    sliderLeft = 0;
    pointerWidth = 0;
    pointerR = null;
    pointerL = null;
    activePointer = null;
    selected = null;
    scale = null;
    step = 0;
    tipL = null;
    tipR = null;
    timeout = null;
    valRange = false;

    values = {
        start: null,
        end: null
    };
    conf = {
        target: null,
        values: null,
        set: null,
        range: false,
        width: null,
        scale: true,
        labels: true,
        tooltip: false,
        step: null,
        disabled: false,
        onChange: null,
        up: false
    };

    cls = {
        container: 'rs-container',
        background: 'rs-bg',
        selected: 'rs-selected',
        pointer: 'rs-pointer',
        scale: 'rs-scale',
        noscale: 'rs-noscale',
        tip: 'rs-tooltip'
    };




    init(conf) {
        for (var i in this.conf) {
            if (conf[i]) {
                this.conf[i] = conf[i];
            }
        }

        if (typeof this.conf.target === 'object') this.input = this.conf.target;
        else this.input = document.getElementById(this.conf.target.replace('#', ''));

        if (!this.input) return window.Logger.log('Cannot find target element...');

        this.inputDisplay = getComputedStyle(this.input, null).display;
        this.input.style.display = 'none';
        this.valRange = !(this.conf.values instanceof Array);



        return this.createSlider();
    }

    setUp(up) {
        this.conf.up = up
        var bg = document.getElementsByClassName("rs-bg")[0];
        var selected = document.getElementsByClassName("rs-selected")[0];

        bg.classList.remove("rs-up-true");
        bg.classList.remove("rs-up-false");
        selected.classList.remove("rs-up-true");
        selected.classList.remove("rs-up-false");

        if (this.conf.up) {
            bg.classList.add("rs-up-true");
            selected.classList.add("rs-up-false");
        } else {
            bg.classList.add("rs-up-false");
            selected.classList.add("rs-up-true");
        }
    }

    createSlider = function () {
        this.slider = this.createElement('div', this.cls.container);
        this.slider.innerHTML = '<div class="rs-bg"></div>';
        this.selected = this.createElement('div', this.cls.selected);
        this.pointerL = this.createElement('div', this.cls.pointer, ['dir', 'left']);
        this.scale = this.createElement('div', this.cls.scale);

        if (this.conf.tooltip) {
            this.tipL = this.createElement('div', this.cls.tip);
            this.tipR = this.createElement('div', this.cls.tip);
            this.pointerL.appendChild(this.tipL);
        }
        this.slider.appendChild(this.selected);
        //this.slider.appendChild(this.scale);
        this.slider.appendChild(this.pointerL);

        if (this.conf.range) {
            this.pointerR = this.createElement('div', this.cls.pointer, ['dir', 'right']);
            if (this.conf.tooltip) this.pointerR.appendChild(this.tipR);
            this.slider.appendChild(this.pointerR);
        }

        this.input.parentNode.insertBefore(this.slider, this.input.nextSibling);

        if (this.conf.width) this.slider.style.width = parseInt(this.conf.width) + 'px';
        this.sliderLeft = this.slider.getBoundingClientRect().left;
        this.sliderWidth = this.slider.clientWidth;
        this.pointerWidth = this.pointerL.clientWidth;

        if (!this.conf.scale) this.slider.classList.add(this.cls.noscale);

        return this.setInitialValues();
    };

    setInitialValues = function () {
        this.disabled(this.conf.disabled);

        if (this.valRange) this.conf.values = this.prepareArrayValues(this.conf);

        this.values.start = 0;
        this.values.end = this.conf.range ? this.conf.values.length - 1 : 0;


        if (this.conf.set && this.conf.set.length && this.checkInitial(this.conf)) {
            var vals = this.conf.set;

            if (this.conf.range) {
                this.values.start = this.conf.values.indexOf(vals[0]);
                this.values.end = this.conf.set[1] ? this.conf.values.indexOf(vals[1]) : null;
            }
            else this.values.end = this.conf.values.indexOf(vals[0]);
        }
        this.setUp(this.conf.up)
        return this.createScale();
    };

    createScale = function () {
        this.step = this.sliderWidth / (this.conf.values.length - 1);
        //var iLen = 0
        for (var i = 0, iLen = this.conf.values.length; i < iLen; i++) {
            var span = this.createElement('span'),
                ins = this.createElement('ins');

            //span.appendChild(ins);
            this.scale.appendChild(span);

            span.style.width = i === iLen - 1 ? 0 : (this.step - 1) + 'px';

            if (!this.conf.labels) {
                if (i === 0 || i === iLen - 1) ins.innerHTML = this.conf.values[i]
            }
            else ins.innerHTML = this.conf.values[i];

            ins.style.marginLeft = (ins.clientWidth / 2) * - 1 + 'px';
        }
        return this.addEvents();
    };

    updateScale = function () {
        this.step = this.sliderWidth / (this.conf.values.length - 1);

        var pieces = this.slider.querySelectorAll('span');
        for (var i = 0, iLen = pieces.length; i < iLen; i++)
            pieces[i].style.width = this.step + 'px';

        return this.setValues();
    };

    addEvents = function () {
        var pointers = this.slider.querySelectorAll('.' + this.cls.pointer),
            pieces = this.slider.querySelectorAll('span'), i;

        this.createEvents(document, 'mousemove touchmove', this.move.bind(this));
        this.createEvents(document, 'mouseup touchend touchcancel', this.drop.bind(this));
        var iLen = 0
        for (i = 0, iLen = pointers.length; i < iLen; i++)
            this.createEvents(pointers[i], 'mousedown touchstart', this.drag.bind(this));

        for (i = 0, iLen = pieces.length; i < iLen; i++)
            this.createEvents(pieces[i], 'click', this.onClickPiece.bind(this));

        window.addEventListener('resize', this.onResize.bind(this));

        return this.setValues();
    };

    drag = function (e) {
        if (e.cancelable) {
            e.preventDefault();
        }

        if (this.conf.disabled) return;

        var dir = e.target.getAttribute('data-dir');
        if (dir === 'left') this.activePointer = this.pointerL;
        if (dir === 'right') this.activePointer = this.pointerR;

        return this.slider.classList.add('sliding');
    };

    move = function (e) {
        if (this.activePointer && !this.conf.disabled) {
            var coordX = e.type === 'touchmove' ? e.touches[0].clientX : e.pageX,
                index = coordX - this.sliderLeft - (this.pointerWidth / 2);

            index = Math.round(index / this.step);

            if (index <= 0) index = 0;
            if (index > this.conf.values.length - 1) index = this.conf.values.length - 1;

            if (this.conf.range) {
                if (this.activePointer === this.pointerL) this.values.start = index;
                if (this.activePointer === this.pointerR) this.values.end = index;
            }
            else this.values.end = index;

            return this.setValues();
        }
    };

    drop = function () {
        this.activePointer = null;
    };

    setValues = function (start, end) {
        var activePointer = this.conf.range ? 'start' : 'end';

        if (start && this.conf.values.indexOf(start) > -1)
            this.values[activePointer] = this.conf.values.indexOf(start);

        if (end && this.conf.values.indexOf(end) > -1)
            this.values.end = this.conf.values.indexOf(end);

        if (this.conf.range && this.values.start > this.values.end)
            this.values.start = this.values.end;

        this.pointerL.style.left = (this.values[activePointer] * this.step - (this.pointerWidth / 2)) + 'px';

        if (this.conf.range) {
            if (this.conf.tooltip) {
                this.tipL.innerHTML = this.conf.values[this.values.start];
                this.tipR.innerHTML = this.conf.values[this.values.end];
            }
            this.input.value = this.conf.values[this.values.start] + ',' + this.conf.values[this.values.end];
            this.pointerR.style.left = (this.values.end * this.step - (this.pointerWidth / 2)) + 'px';
        }
        else {
            if (this.conf.tooltip)
                this.tipL.innerHTML = this.conf.values[this.values.end];
            this.input.value = this.conf.values[this.values.end];
        }

        if (this.values.end > this.conf.values.length - 1) this.values.end = this.conf.values.length - 1;
        if (this.values.start < 0) this.values.start = 0;

        this.selected.style.width = (this.values.end - this.values.start) * this.step + 'px';
        this.selected.style.left = this.values.start * this.step + 'px';

        return this.onChange();
    };

    onClickPiece = function (e) {

        if (this.conf.disabled) return;

        var idx = Math.round((e.clientX - this.sliderLeft) / this.step);

        if (idx > this.conf.values.length - 1) idx = this.conf.values.length - 1;
        if (idx < 0) idx = 0;

        if (this.conf.range) {
            if (idx - this.values.start <= this.values.end - idx) {
                this.values.start = idx;
            }
            else this.values.end = idx;
        }
        else this.values.end = idx;

        this.slider.classList.remove('sliding');

        return this.setValues();
    };
    prevVal = -1
    onChange = function () {
        if (this.prevVal != this.input.value) {
            this.prevVal = this.input.value
            if (this.conf.onChange && typeof this.conf.onChange === 'function') {
                return this.conf.onChange(this.input.value);
            }
        }

    };

    onResize = function () {
        this.sliderLeft = this.slider.getBoundingClientRect().left;
        this.sliderWidth = this.slider.clientWidth;
        return this.updateScale();
    };

    disabled = function (disabled) {
        this.conf.disabled = disabled;
        this.slider.classList[disabled ? 'add' : 'remove']('disabled');
    };

    getValue = function () {
        return this.input.value;
    };

    destroy = function () {
        this.input.style.display = this.inputDisplay;
        this.slider.remove();
    };


    createElement = function (el, cls, dataAttr) {
        var element = document.createElement(el);
        if (cls) element.className = cls;
        if (dataAttr && dataAttr.length === 2)
            element.setAttribute('data-' + dataAttr[0], dataAttr[1]);

        return element;
    }

    createEvents = function (el, ev, callback) {
        var events = ev.split(' ');

        for (var i = 0, iLen = events.length; i < iLen; i++)
            el.addEventListener(events[i], callback);
    }

    prepareArrayValues = function (conf) {
        var values = [],
            range = conf.values.max - conf.values.min;

        if (!conf.step) {
            window.Logger.log('No step defined...');
            return [conf.values.min, conf.values.max];
        }

        for (var i = 0, iLen = (range / conf.step); i < iLen; i++)
            values.push(conf.values.min + i * conf.step);

        if (values.indexOf(conf.values.max) < 0) values.push(conf.values.max);

        return values;
    }

    checkInitial = function (conf) {
        if (!conf.set || conf.set.length < 1) return null;
        if (conf.values.indexOf(conf.set[0]) < 0) return null;

        if (conf.range) {
            if (conf.set.length < 2 || conf.values.indexOf(conf.set[1]) < 0) return null;
        }
        return true;
    };
}