<template>
  <div class="gamemodeholder">
    <div
      class="gamemodeinside"
      v-bind:class="
        active ? 'gamemodeinsideAuto' : 'gamemodeinsideManual'
      "
    ></div>
    <div class="gamemodeinsideTxt">
      <div
        @click="setAutoMode(false)"
        class="gamemodeinsideTxtLeft"
        v-bind:class="
          active == true ? 'gamemodetxtActive' : 'gamemodetxtDeactive'
        "
      >
        {{this.leftText}}
      </div>
      <div
        @click="setAutoMode(true)"
        class="gamemodeinsideTxtRight"
        v-bind:class="
          active == true ? 'gamemodetxtDeactive' : 'gamemodetxtActive'
        "
      >
        {{this.rightText}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
    };
  },
  props: ["changeHandler","name","leftText","rightText"],
  methods: {
    setAutoMode(mode) {
      this.active = mode;
      this.changeHandler(mode,this.name)
    },
  },
};
</script>
<style>
.gamemodeholder {
  background: #e0e0e0;
  width: 100%;
  height: 50px;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  margin: 0 auto;
}
.gamemodeinside {
  background: #ffffff;
  width: 50%;
  height: 40px;
  margin: 0px;
  padding: 5px;
  border-radius: 5px;
}

.gamemodeinsideManual {
  float: left;
  transition: 1s;
}
.gamemodeinsideAuto {
  float: right;
  transition: 1s;
}

.gamemodeinsideTxtLeft {
  width: 45%;
  text-align: center;
  float: left;
}

.gamemodetxtDeactive {
  color: #333333;
}

.gamemodetxtActive {
  color: #939393;
}

.gamemodeinsideTxtRight {
  width: 50%;
  text-align: center;
  float: right;
  margin-right: 5px;
}
.gamemodeinsideTxt {
  position: absolute;
  line-height: 40px;
  width: 100%;
}
</style>
