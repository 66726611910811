<template>
  <div class="table-responsive mb-3 dicetbl">
    <table class="table">
      <thead>
        <td>PLAYER</td>
        <td>GAME</td>
        <td>RESULT</td>
        <td>BET</td>
        <td>WIN</td>
      </thead>
      <tbody>
        <tr
          v-for="game in data"
          :key="game.index"
          :class="game.win != 0 ? 'table-success' : 'table-light'"
        >
          <td>
            <a
              v-if="game.address != 'ME'"
              :href="scanner.tx + game.tx_id"
              target="_address"
              ><span class="truncate">{{ game.address }}</span></a
            >
            <span v-else>{{ game.address }}</span>
          </td>
          <td>
            {{
              game.up == 0 ? "&lt; " + game.position : "&gt; " + game.position
            }}
          </td>
          <td>
            {{ game.number }}
          </td>
          <td class="text-end"><span class="responsiveElipsis">{{ parseFloat(game.bet).toFixed(5) * 1 }}</span></td>
          <td class="text-end">
            <span class="responsiveElipsis">{{ parseFloat(game.win).toFixed(5) * 1 }}</span> 
          <img
              class="currImg"
              :src="
                game.currency == null
                  ? '/images/tokens/tt-icon.svg'
                  : '/images/tokens/' + game.currency + '-icon.svg'
              "
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: "DiceHistoryTable",
  data() {
    return {
      scanner: {
        tx: this.$store.getters.getConfig.tx
      },
    };
  },
  props: ["data"],
};
</script>
<style scoped>
.dicetbl {
  font-size: 14px;
}
.currImg {
  width: 22px;
  position: relative;
  top: -6px;
  left: 2px;
}

.responsiveElipsis {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 401px) {
  .responsiveElipsis {
    max-width: 70px;
  }
}

@media (max-width: 329px) {
  .responsiveElipsis {
    max-width: 60px;
  }
}
</style>